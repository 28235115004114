export default function useResponsive() {
  const isScrolled = ref(false);
  const scrollPos = ref(0);
  
  onMounted(() => {
    handleScroll();
    window.addEventListener('scroll', handleScroll);
  });

  const handleScroll = () => {
    if (scrollPos.value < window.scrollY) {
      isScrolled.value = true;
    }

    if (scrollPos.value > window.scrollY) {
      isScrolled.value = false;
    }

    setTimeout(() => {
      scrollPos.value = window.scrollY;
    }, 500);

  };

  return {
    isScrolled,
    scrollPos,
  };
}
