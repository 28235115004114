<template>
  <div ref="desktopMenu">
    <div
      class="DesktopMenu mobOnly:hidden w-full h-44
                bg-lightest text-darkest
                shadow-bottomLine
                sticky top-[53px] z-underHeader
                transition-transform duration-300
                select-none
                "
      :class="{
        hide: isScrolled && scrollPos > 44,
      }"
    >
      <div
        class="h-44 layout-container flex justify-between items-center
      type-headline-xxs deskStd:type-headline-xs leading-single fixBaseline"
      >
        <div class="flex items-center justify-start">
          <div
            v-for="link in mainNavigation"
            :key="link._uid"
            class="navOptionWrapper"
          >
            <button
              v-if="link.children.length > 0"
              class="navOption type-headline-xxs deskStd:type-headline-xs"
              :class="{
                'selected': uiStore.isParentOfOpenMenu(link._uid),
              }"
              @click="clickMenuDesktop($sbLinkFix(link.link), (link.children.length > 0), link._uid, true)"
            >
              <span
                :class="{
                  'text-brightRed': checkDesign(link.design, 'sale'),
                }"
              >{{ link.title }}</span>
            </button>
            <nuxt-link
              v-else-if="$sbLinkCheck(link.link)"
              :to="$sbLinkFix(link.link)"
              :target="$sbLinkTarget(link.link)"
              class="navOption"
              :class="{
                'text-brightRed': checkDesign(link.design, 'sale'),
              }"
            >
              {{ link.title }}
            </nuxt-link>
          </div>
        </div>
        <div class="flex">
          <!--          <div class="navOptionWrapper">
            <nuxt-link to="/no/iveo/empty" class="navOption">emtpy</nuxt-link>
          </div>
          <div class="navOptionWrapper">
            <nuxt-link to="/no/produkt/session-label-the-salt-200ml" class="navOption">prod</nuxt-link>
          </div>-->
          <div
            v-for="link in secondaryNavigation"
            :key="link._uid"
            class="navOptionWrapper"
          >
            <button
              v-if="link.children.length > 0"
              class="navOption"
              :class="{
                'selected': uiStore.isParentOfOpenMenu(link._uid),
              }"
              @click="clickMenuDesktop($sbLinkFix(link.link), (link.children.length > 0), link._uid)"
            >
              <span
                :class="{
                  'text-brightRed': checkDesign(link.design, 'sale'),
                }"
              >{{ link.title }}</span>
            </button>
            <nuxt-link
              v-else-if="$sbLinkCheck(link.link)"
              :to="$sbLinkFix(link.link)"
              :target="$sbLinkTarget(link.link)"
              class="navOption"
              :class="{
                'text-brightRed': checkDesign(link.design, 'sale'),
              }"
            >
              {{ link.title }}
            </nuxt-link>
          </div>
        </div>
      </div>
    </div>

    <div class="relative w-full">
      <DesktopMenuExpanded />
    </div>
  </div>
</template>

<script setup lang="ts">
import useCheckScroll from '~/composeables/useCheckScroll';
import useMenuHelper from '~/composeables/useMenuHelper';
import { onClickOutside } from '@vueuse/core';

import { useGlobalContentStore } from '~/store/globalContent';
import { useUiStore } from '~/store/ui';
import DesktopMenuExpanded from '~/components/menu/DesktopMenuExpanded.vue';

const globalContent = useGlobalContentStore();
const mainNavigation = globalContent.getNavigation('mainNavigation');
const secondaryNavigation = globalContent.getNavigation('secondaryNavigation');

const uiStore = useUiStore();
const { isScrolled, scrollPos } = useCheckScroll();
const { checkDesign, clickMenuDesktop } = useMenuHelper();

const desktopMenu = ref<HTMLElement>();

onClickOutside(desktopMenu, () => {
  uiStore.closeDesktopMenu();
});

</script>

<style scoped lang="postcss">
.navOption {
  @apply flex items-center uppercase h-[41px] border-b border-transparent hover:border-darkGreen;
  &.selected {
    @apply border-darkest;
  }
}
.navOptionWrapper {
  @apply desk:mr-12 deskStd:mr-20 last-of-type:mr-0;
}
.hide {
  transform: translateY(-100%);
}

</style>
