<template>
  <nuxt-link
    :to="$sbLinkFix(item.link)"
    :target="$sbLinkTarget(item.link)"
    class="MenuItemFavorite flex mb-12 items-center"
  >
    <div v-if="brandlink?.brandCode && userStore.isLoggedIn">
      <img
        :src="isUserFav ? '/icons/favorite-filled.svg' : '/icons/favorite.svg'"
        class="w-20 h-20 mr-12"
        alt="Favorite"
      >
    </div>
    <div>{{ item.title }}</div>
  </nuxt-link>
</template>

<script setup lang="ts">
import { StoryBlokNestableNav } from '~/constants/types/storyblok';
import { useUserStore } from '~/store/user';

import { useGlobalContentStore } from '~/store/globalContent';

const userStore = useUserStore();
const globalContent = useGlobalContentStore();

const props = defineProps<{
  item: StoryBlokNestableNav;
}>();

const { $sbLinkFix } = useNuxtApp();

const brandlink = computed(()=>{
  // removes first and last slash
  const link = $sbLinkFix(props.item.link).replace(/^\/+/, '').replace(/\/+$/, '');
  return globalContent.brandList.find((f) => {
    const thisLink = f.brandLink.replace(/^\/+/, '').replace(/\/+$/, '');
    return thisLink === link;
  });
});

const isUserFav = computed(()=> {
  if (!brandlink.value) {
    return false;
  }
  return userStore.userProfile.favoriteBrands.includes(brandlink.value?.brandCode);
});

</script>

<style scoped lang="postcss">
</style>
