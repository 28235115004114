<template>
  <div
    class="DesktopMenuExpanded absolute top-0 z-[850] w-full bg-lightest shadow-bottomLine
    transition-transform duration-300 inital-value "
    :class="{
      'start-hidden': !showMenu
    }"
  >
    <div
      v-if="nav1open?.mobileButton && showAllChildren && $sbLinkCheck(nav1open.link)"
      class="w-full h-56 shadow-bottomLine flex items-center"
    >
      <div class="layout-container">
        <nuxt-link
          :to="$sbLinkFix(nav1open.link)"
          class="underline type-sm-medium"
        >
          {{ nav1open?.mobileButton }}
        </nuxt-link>
      </div>
    </div>

    <div
      v-if="showAllChildren"
      class="layout-container grid grid-cols-3 relative gap-18"
    >
      <div
        v-for="child in allChildren"
        :key="child._uid"
        class="type-sm  border-r border-light last-of-type:border-0 pb-18"
      >
        <div class="py-18  type-headline-sm">{{ child.title }}</div>
        <div class="grid grid-cols-2">
          <div>
            <MenuItemFavorite
              v-for="item in child.children.slice(0, halfOfLongest)"
              :key="item._uid"
              :item="item"
            />
          </div>
          <div>
            <MenuItemFavorite
              v-for="item in child.children.slice(halfOfLongest)"
              :key="item._uid"
              :item="item"
            />
          </div>
        </div>
      </div>
    </div>

    <div
      v-else
      class="layout-container grid grid-cols-4 relative"
    >
      <DesktopMenuContent
        v-for="(submenu, index) in sideMenuParents.slice(0,4)"
        :key="submenu"
        :parent="submenu"
        :depth="index"
        :style="{
          zIndex: 1000 - index
        }"
      />

      <div
        v-if="hasTeasers"
        class=" flex justify-end"
        :class="{
          'col-span-3': depth === 1,
          'col-span-2': depth === 2
        }"
      >
        <nuxt-link
          v-for="teaser in topOpen?.teasers.slice(0, 4 - depth)"
          :key="teaser._uid"
          :to="$sbLinkFix(teaser.link)"
          :target="$sbLinkTarget(teaser.link)"
          class=" shrink-0 pt-16 pl-8"
          :class="{
            'basis-1/3': depth === 1,
            'basis-1/2': depth === 2,
            'w-full': depth >= 3
          }"
        >
          <nuxt-img
            v-if="teaser.image && teaser.image.filename"
            preset="standard"
            loading="lazy"
            sizes="mob375:100vw mob390:100vw mob414:100vw desk:319px"
            :src="teaser.image.filename"
            :alt="teaser.image.alt"
            class="mb-16"
          />
          <div class="type-headline-xs underline leading-single mb-12">{{ teaser.category }}</div>
          <div class="type-headline-lg">{{ teaser.headline }}</div>
        </nuxt-link>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">

import DesktopMenuContent from '~/components/menu/DesktopMenuContent.vue';

import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import { useGlobalContentStore } from '~/store/globalContent';
import { StoryBlokNestableNav } from '~/constants/types/storyblok';
import MenuItemFavorite from '~/components/menu/MenuItemFavorite.vue';
const globalContent = useGlobalContentStore();

const uiStore = useUiStore();
const { sideMenuParents } = storeToRefs(uiStore);

const showMenu = ref(false);

const menuLength = computed(()=> {
  return sideMenuParents.value.length;
});

const nav1open = computed(()=> {
  if (sideMenuParents.value[0]) {
    return globalContent.getNavByUid(sideMenuParents.value[0]);
  }
  return null;
});
const showAllChildren = computed(()=> {
  return (nav1open.value?.showAllChildren === true);
});
const allChildren = computed<StoryBlokNestableNav[]>(()=> {
  if (sideMenuParents.value[0]) {
    return globalContent.getNavChildrenByUid(sideMenuParents.value[0]).slice(0,3);
  }
  return [];
});

const depth = computed(()=> {
  return sideMenuParents.value.length || 0;
});

/**
 * We need to split into 2 columns based on longest
 */
const halfOfLongest = computed(()=> {
  const longest = allChildren.value.map((i)=> i.children.length);
  if (!longest) {
    return 0;
  }
  return Math.ceil(Math.max(...longest) / 2);
});

const topOpen = computed(()=> {
  if (!sideMenuParents.value.length) {
    return null;
  }
  return globalContent.getNavByUid(sideMenuParents.value[sideMenuParents.value.length - 1]);
});

const hasTeasers = computed(()=> {
  if (topOpen.value && topOpen.value?.teasers && topOpen.value.teasers.length) {
    return true;
  }
  return false;
});

watch(menuLength, (newVal, oldValue) => {
  if (newVal === 0) {
    showMenu.value = false;
  }

  if (newVal > oldValue) {
    showMenu.value = true;
  }
});

onMounted(()=> {
  globalContent.loadBrands();
});

</script>

<style scoped lang="postcss">
.inital-value {
  transform: translateY(0%);
}
.start-hidden {
  transform: translateY(-100%);
}
</style>
